.mega-menu {
  position: static;
}

.mega-menu__content {
  background-color: rgb(var(--color-background));
  border-left: 0;
  border-radius: 0;
  border-right: 0;
  left: 0;
  overflow-y: auto;
  padding-bottom: 3rem;
  padding-top: 3rem;
  position: absolute;
  right: 0;
  top: 100%;
}

.shopify-section-header-sticky .mega-menu__content {
  max-height: calc(100vh - var(--header-bottom-position-desktop, 20rem) - 4rem);
}

.header-wrapper--border-bottom .mega-menu__content {
  border-top: 0;
}

.js .mega-menu__content {
  opacity: 0;
  transform: translateY(-1.5rem);
}

.mega-menu[open] .mega-menu__content {
  opacity: 1;
  transform: translateY(0);
}

.mega-menu__list {
  display: grid;
  gap: 1.8rem 4rem;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  list-style: none;
}

.mega-menu__link {
  color: rgba(var(--color-foreground), 0.75);
  display: block;
  line-height: calc(1 + 0.3 / var(--font-body-scale));
  padding-bottom: 0.6rem;
  padding-top: 0.6rem;
  text-decoration: none;
  transition: text-decoration var(--duration-short) ease;
  word-wrap: break-word;
}

.mega-menu__link--level-2 {
  font-weight: bold;
}

.header--top-center .mega-menu__list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 0;
}

.header--top-center .mega-menu__list > li {
  width: 16%;
  padding-right: 2.4rem;
}

.mega-menu__link:hover,
.mega-menu__link--active {
  color: rgb(var(--color-foreground));
  text-decoration: underline;
}

.mega-menu__link--active:hover {
  text-decoration-thickness: 0.2rem;
}

.mega-menu .mega-menu__list--condensed {
  display: block;
}

.mega-menu__list--condensed .mega-menu__link {
  font-weight: normal;
}
